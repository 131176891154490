.red {
  color: red;
}

.bold {
  font-weight: bold;
}

.Cockpit button {
  border: 1px solid green;
  padding: 16px;
  background-color: green;
  font: inherit;
  color: white;
  cursor: pointer;
}

.Cockpit button:hover {
  background-color: lightgreen;
  color: black;
}

.Cockpit button.Red {
  background-color: red;
}

.Cockpit button.Red:hover {
  background-color: salmon;
  color: black;
}
