.Person {
  background-color: white;
  font: inherit;
  border: 1px solid black;
  padding: 8px;
  margin: auto;
  margin-bottom: 8px;
  width: 60%;
  cursor: pointer;
}

@media (min-width: 600px) {
  .Person {
    width: 400px;
    background-color: yellow;
  }
}
